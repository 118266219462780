/* eslint-disable no-restricted-imports */
import { getMyEnv } from '@lessonnine/my.js';
import { getSubdomainFromLocale } from 'src/dataLayer/localData/languageCodes';
import { getZendeskLocale } from 'src/dataLayer/localData/zendeskConfig';
import { getReturnToUrl } from 'src/dataLayer/localData/queryParams';
import interpolate from './helpers/interpolate';
import { getRawQueryString } from '../localData/queryParams';
import process from 'process';

const {
  uuid,
  environment,
  staticBaseUrlTemplate,
  myBaseUrl,
  apigatewayBaseUrl,
  assetUrl,
  homeBaseUrl,
  locale,
  signInUrl,
  rollbarAccessToken,
  awsSessionEndpoint,
  awsSessionMethod
} = getMyEnv();

// User Data
export const UUID = uuid;
// TODO: Always get locale from here instead of useRouteParamsContext hook
export const LOCALE = locale;
export const ROLLBAR_ACCESS_TOKEN = rollbarAccessToken;

// Env
export const RUNTIME_ENVIRONMENT = environment;
export const isPrivateEnv = () => new Set(['development', 'test', undefined]).has(environment);

// URLs
export const ASSET_URL = assetUrl;
export const APIGATEWAY_BASE_URL = apigatewayBaseUrl;
export const DASHBOARD_URL = `${myBaseUrl}/dashboard`;
export const TRACKING_URL = `${APIGATEWAY_BASE_URL}/v1/events`;
export const ROLLBAR_API_URL = 'https://api.rollbar.com/api/1/item/';
export const SIGN_IN_URL = signInUrl;
export const AWS_SESSION_ENDPOINT = awsSessionEndpoint;
export const AWS_SESSION_METHOD = awsSessionMethod;

const OAUTH_SIGNUP_URL_TEMPLATE = `${homeBaseUrl}/%{locale}/` +
  `oauth_session/new?provider=%{provider}&return_to=%{returnTo}`;
const faqURLTemplate = 'https://support.babbel.com/hc/%{locale}/categories/200333238-Frequently-asked-questions';

// skipcq: JS-0123 - can be refactored separately
export const getRegistrationUrl = (locale) => {
  return `${myBaseUrl}/${locale}/onboarding/default/0${getRawQueryString()}`;
};

// skipcq: JS-0123 - can be refactored separately
export const getIndexPageURL = (locale) =>
  interpolate(staticBaseUrlTemplate, { subdomain: getSubdomainFromLocale(locale) });

// skipcq: JS-0123 - can be refactored separately
export const getForgotPasswordURL = (locale) => {
  const accountsURL = interpolate(staticBaseUrlTemplate, { subdomain: 'accounts' });
  const returnToURL = getReturnToUrl(window.location.search);
  // Only set the returnToParam if it is not the dashboard url, since home takes care of sending to dashboard anyways
  const returnToParam = (returnToURL && returnToURL !== encodeURIComponent(DASHBOARD_URL))
    ? `?return_to=${ returnToURL }`
    : '';
  return `${accountsURL}/${locale}/accounts/password/new${returnToParam}`;
};

// skipcq: JS-0123 - can be refactored separately
export const getOauthSignupURL = (locale, provider) =>
  interpolate(OAUTH_SIGNUP_URL_TEMPLATE, {
    locale,
    provider,
    returnTo: getReturnToUrl(window.location.search)
  });

// skipcq: JS-0123 - can be refactored separately
export const getBusinessSuccessPageURL = (locale, environment) =>
  `${myBaseUrl}/${locale}/${environment}/login-mobile/business/success`;

export const getFAQURL = () => {
  return interpolate(faqURLTemplate, { locale: getZendeskLocale() });
};

// Buildtime
export const BABBEL_APP_VERSION = process.env.BABBEL_APP_VERSION;
// TODO: needs to be exposed via repositories.terraform
export const BEARER_TOKEN = process.env.BEARER_TOKEN;
