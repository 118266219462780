import {
  Button as InternalButton
} from '@lessonnine/design-system.lib';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const Button = {
  [DS_VERSIONS.default]: InternalButton
};

export default Button[CURRENT_DS_VERSION];
