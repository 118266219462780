import {
  BabbelFonts as InternalBabbelFonts,
  BodyText as InternalText,
  Heading as InternalHeading,
  Title as InternalTitle
} from '@lessonnine/design-system.lib';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const Typography = {
  [DS_VERSIONS.default]: {
    BabbelFonts: InternalBabbelFonts,
    Heading: InternalHeading,
    Text: InternalText,
    Title: InternalTitle
  }
};

export default Typography[CURRENT_DS_VERSION];
