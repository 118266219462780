const defaultThemeDefaultProps = {
  theme: {
    color: {
      neutral: {
        surfaceDefault: 'none',
        onSurfaceSubdued2: 'none',
      }
    }
  }
};

export const getThemeDefaultProps = () =>
  defaultThemeDefaultProps;
