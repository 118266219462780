import React from 'react';

import {
  IconAppleColored as InternalIconAppleColoredRebranded,
  IconCaretLeftBold as InternalIconBackArrowRebranded,
  IconClose as InternalIconCloseRebranded,
  IconGoogleColored as InternalIconGoogleColoredRebranded,
  IconFacebookColored as InternalIconFacebookColoredRebranded,
  IconGlobe as InternalIconGlobeRebranded,
  IconEyeClosed as InternalIconHideRebranded,
  IconLock as InternalIconLockRebranded,
  IconEnvelopeSimple as InternalIconMailRebranded,
  IconEye as InternalIconShowRebranded,
  IconXCircle as InternalIconXCircleRebranded
} from '@lessonnine/design-system.lib';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const IconsMap = {
  [DS_VERSIONS.default]: {
    IconAppleColored: ({ props }) => <InternalIconAppleColoredRebranded size="large" {...props} />,
    IconBackArrow: InternalIconBackArrowRebranded,
    IconClose: InternalIconCloseRebranded,
    IconGoogleColored: ({ props }) => <InternalIconGoogleColoredRebranded size="large" {...props} />,
    IconFacebookColored: ({ props }) => <InternalIconFacebookColoredRebranded size="large" {...props} />,
    IconGlobe: InternalIconGlobeRebranded,
    IconHide: InternalIconHideRebranded,
    IconLock: InternalIconLockRebranded,
    IconMail: InternalIconMailRebranded,
    IconShow: InternalIconShowRebranded,
    IconXCircle: InternalIconXCircleRebranded
  }
};

export default IconsMap[CURRENT_DS_VERSION];
