import React from 'react';
import { Stack, Text } from 'src/viewLayer/components/DesignSystem';
import { ResponsiveHeader } from 'src/viewLayer/components/shared/ResponsiveHeader';

export default ({ title, subtitle }) => {
  const largeSizeHeader = 'large';

  return (
    <Stack
      className="HeaderComponent"
      alignItems="center"
      gap={ 16 }
      padding={[0, 0, 24, 0]}
    >
      {title &&
        <ResponsiveHeader largeSize={largeSizeHeader}>{title}</ResponsiveHeader>
      }
      {subtitle && (<Text>{subtitle}</Text>)}
    </Stack>
  );
};
