import {
  Tag as InternalBadge
} from '@lessonnine/design-system.lib';

import { DS_VERSIONS } from 'src/dataLayer/localData/designSystem/versions';
import { CURRENT_DS_VERSION } from 'src/dataLayer/localData/designSystem';

const Badge = {
  [DS_VERSIONS.default]: InternalBadge
};

export default Badge[CURRENT_DS_VERSION];
