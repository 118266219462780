import React from 'react';
import { Title } from 'src/viewLayer/components/DesignSystem';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';
import useViewport from 'src/viewLayer/components/hooks/useViewport';
import { ResponsiveHeaderStyled } from './index.styled';

const mediumBreakpoint = Number(tokens.viewports.breakpoints.medium.slice(0, -2));

export const ResponsiveHeader = ({
  children,
  changeBreakpoint = mediumBreakpoint,
  color,
  isCentered = true,
  smallSize = 'small',
  largeSize = 'default'
}) => {
  const viewport = useViewport();
  const headingSize =
    viewport < changeBreakpoint ? smallSize : largeSize;

  return (
    <ResponsiveHeaderStyled isCentered={isCentered}>
      <Title color={color} textAlign={isCentered && 'center'} size={headingSize}>{children}</Title>
    </ResponsiveHeaderStyled>
  );
};
