import styled from 'styled-components';
import devices from 'src/viewLayer/styling/mixins/media-queries';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

import { getColoursFromTheme, Box, Heading, Layout, Text, IconXCircle } from 'src/viewLayer/components/DesignSystem';
import { getThemeDefaultProps } from 'src/viewLayer/styling/helpers';

export const BusinessErrorPageWithBackground = styled.div`
  background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.foregroundColor};
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  height: 100%;
  @media only screen and ${devices.fromsmall} {
    background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.backgroundColor};
  }
`;

export const BusinessErrorPageLayout = styled(Layout)`
  height: 100%;
  overflow: hidden;

  @media only screen and ${devices.fromsmall} {
    margin: 0 auto;
    max-width: 900px;
    overflow: unset;
    position: relative;
  }
`;

export const BusinessErrorCard = styled(Box)`
  background-color: ${({ theme }) => getColoursFromTheme(theme).authContainer.loginContainer.backgroundColor};
  border-radius: ${tokens.size.radius.small};
  box-shadow: none;
  margin-top: 2.063rem;
  margin-bottom: 2.75rem;
  z-index: 30;

  @media only screen and ${devices.fromxxsmall} {
    width: 19.75rem;
  }
  @media only screen and ${devices.fromxsmall} {
    width: 34.75rem;
  }
  @media only screen and ${devices.fromsmall} {
    box-shadow: unset;
    width: 35.625rem;
  }
  @media only screen and ${devices.frommedium} {
    border-radius: 0 ${tokens.size.radius.large} ${tokens.size.radius.large} ${tokens.size.radius.large};
  }
`;

export const BusinessErrorCardTitle = styled(Heading)`
  text-align: center;
  line-height: 2rem;
`;

export const BusinessErrorCardMessage = styled(Text)`
  text-align: center;
`;

export const BackgroundItemsContainer = styled(Box)`
  .ScribbleSVG, .HandCardSVG {
      display: none;
      position: absolute;
      z-index: 10;

      @media only screen and ${devices.fromsmall} {
        display: initial;
      }
    }
    .ScribbleSVG {
      right: 0;
      top: -5%;
    }
    .HandCardSVG {
      left: -6%;
      top: 30%;
    }
`;

export const BusinessErrorPageImage = styled(IconXCircle)`
  height: 6rem;
  width: 6rem;
`;

BusinessErrorCard.defaultProps = getThemeDefaultProps();
BusinessErrorPageLayout.defaultProps = getThemeDefaultProps();
BusinessErrorPageWithBackground.defaultProps = getThemeDefaultProps();
