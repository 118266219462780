/*
All design system components should be exported from this file so that we only
need to replace them in a single place whenever we need to switch themes or upgrade
the design system version with breaking changes that may affect props
*/
import Icons from './Icons';
import LayoutComponents from './Layout';
import TextfieldComponents from './TextField';
import Typography from './Typography';
import Theme from './Theme';
export * from './colours';

export { default as Badge } from './Badge';
export { default as Button } from './Button';
export { default as Footer } from './Footer';
export { default as Link } from './Link';
export { default as NavBarWrapper } from './NavBar';
export { default as Spinner } from './Spinner';

export const {
  IconAppleColored, IconBackArrow, IconClose, IconGoogleColored,
  IconFacebookColored, IconGlobe, IconHide, IconLock,
  IconMail, IconShow, IconXCircle
} = Icons;
export const { Box, Inline, Layout, Stack } = LayoutComponents;
export const { TextField, TextFieldButton } = TextfieldComponents;
export const { BabbelFonts, Heading, Text, Title } = Typography;
export const { BabbelThemeProvider, useTheme } = Theme;
